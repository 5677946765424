import Vue from 'vue'
import axios from '@/plugins/axios'
import store from '@/store'
import utils from '@/helpers/utilidades'

const baseURL = process.env.VUE_APP_API_TESORERIA;

class Pagos
{
  obtener_factura(id) {
    return axios.get(`${baseURL}/tesoreria/facturas/pagos/${id}`);
  }

  obtener_facturas(options) {
    return axios.get(utils.url_options(`${baseURL}/tesoreria/facturas/pagos`, options));
  }

  total_facturas() {
    return axios.get(`${baseURL}/tesoreria/facturas/pagos/total_facturas`)
  }

  realizar_pagos(facturas) {
    return axios.post(`${baseURL}/tesoreria/facturas/pagos/pagar_facturas`, facturas);
  }

  realizar_pago_externo(payload) {
    return axios.post(`${baseURL}/tesoreria/facturas/pagos/pagar_facturas_externas`, payload);
  }
}

export default new Pagos()